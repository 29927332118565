














import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import AlertForm from "@/alerts-system/components/AlertForm.vue";
import AlertRequestModel from "@/alerts-system/models/AlertRequestModel";
import { MenuItems } from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";

@Component({
  components: {
    AlertForm,
  },
})
export default class NewAlertView extends mixins(UnsavedChangesMixin) {
  localValue: AlertRequestModel = new AlertRequestModel();

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localValue);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("alertsSystem.titleForCreating")
    );
    this.handleWatchingUnsavedChanges();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async handleSave() {
    await this.$store.dispatch("createAlert", this.localValue);
    this.isSavedForm = true;
    this.$router.push({ name: MenuItems.USER_ALERTS });
  }
}
